<template>
  <TTView>
    <VRow>
      <VCol>
        <VBreadcrumbs
          class="pl-1"
          :items="breadcrumbs"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <ProcedureForm
          :loading="loading"
          :competencies="competencies"
          :skills="skills"
          :entity="procedure"
          :read-only="readOnly"
          @update:name="procedure.name = $event"
          @update:competencyId="procedure.competencyId = $event"
          @update:skillId="procedure.skillId = $event"
          @update:startAt="procedure.startAt = $event"
          @update:finishedAt="procedure.finishedAt = $event"
          @update:cooldown="procedure.cooldown = $event"
          @update:description="procedure.description = $event"
          @update:continuous="procedure.continuous = $event"
          @update:searchSkills="fetchSkills($event)"
          @update:searchCompetencies="fetchCompetencies($event)"
          @submit="handleProcedure"
          @cancel="handleCancel"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import _ from 'lodash';
import ProcedureForm from '../../components/assessments/ProcedureForm.vue';

const createProcedureDTO = {
  name: null,
  skillId: null,
  competencyId: null,
  cooldown: null,
  continuous: null,
  startAt: null,
  finishedAt: null,
  description: null,
  steps: [],
};

export default {
  name: 'ProcedureFormView',
  components: { ProcedureForm },
  inject: ['Names'],
  props: {
    accountId: { type: String, required: true },
    companyId: { type: String, required: true },
    groupId: { type: String, required: true },
    procedureId: { type: String, default: null },
  },
  data() {
    return {
      procedure: { id: this.procedureId, ..._.cloneDeep(createProcedureDTO) },
      skills: { loading: true, items: [] },
      competencies: { loading: true, items: [] },
      loading: false,
      company: {},
      group: {},
    };
  },
  computed: {
    breadcrumbs() {
      const breadcrumbs = [
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.companyName },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_GROUPS }, exact: true, text: 'Группы оценки' },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_GROUPS_VIEW }, exact: true, text: this.groupName },
      ];

      return breadcrumbs;
    },
    companyName() {
      return this.company?.name ? this.company.name : `Компания: ${this.companyId}`;
    },
    groupName() {
      return this.group?.name ? this.group.name : `Группа оценки: ${this.groupId}`;
    },
    readOnly() {
      return this.procedure.id ? ['name', 'competencyId', 'skillId'] : [];
    },
  },
  watch: {
    async procedureId() {
      if (this.procedureId) {
        await this.fetchProcedure();
      }
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const assessmentApp = new this.$di.ddd.Assessment();
        const accountsApp = new this.$di.ddd.Accounts();

        const { groupId, companyId } = this;
        const [[company], [group]] = await Promise.all([
          await accountsApp.services.account.getCompany({ id: companyId }),
          await assessmentApp.services.assessment.fetchGroup({ groupId }),
        ]);
        this.company = { ...company };
        this.group = { ...group };

        if (this.procedureId) {
          await this.fetchProcedure();
        }
        await Promise.all([
          this.fetchSkills(this.procedure.skillId),
          this.fetchCompetencies(this.procedure.competencyId),
        ]);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async fetchProcedure() {
      const assessmentApp = new this.$di.ddd.Assessment();
      const [procedure] = await assessmentApp.services.assessment.getProcedure(
        { id: this.procedureId, groupId: this.groupId },
      );
      this.procedure = { ...procedure };
    },

    async fetchSkills(query = '') {
      this.skills.loading = true;
      try {
        const { skills } = await this.$di.api.CompetencyMatrix.SkillsSearch({ query });
        this.skills.items = skills;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.skills.loading = false;
      }
    },

    async fetchCompetencies(query = '') {
      this.competencies.loading = true;
      try {
        const { competencies } = await this.$di.api.CompetencyMatrix.CompetenciesSearch({ query });
        this.competencies.items = competencies;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.competencies.loading = false;
      }
    },

    async handleProcedure() {
      try {
        this.loading = true;
        let procedure;
        const assessmentApp = new this.$di.ddd.Assessment();
        const dto = _.cloneDeep(this.procedure);
        if (!dto.id) {
          [procedure] = await assessmentApp.services.assessment.createProcedure(dto, this.groupId);
        } else {
          [procedure] = await assessmentApp.services.assessment.updateProcedure(dto, this.groupId);
        }
        const msg = dto.id ? `Процедура ${dto.name} отредактирована` : `Процедура ${dto.name} добавлена`;
        this.$di.notify.snackSuccess(msg);
        this.$router.push({
          name: this.Names.R_LEARNING_ASSESSMENT_PROCEDURE_VIEW,
          params: {
            procedureId: `${procedure.id}`,
          },
        });
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    handleCancel() {
      this.$router.push({ name: this.Names.R_LEARNING_ASSESSMENT_GROUPS_VIEW });
    },
  },
};

</script>
