<template>
  <VForm>
    <VContainer fluid>
      <VRow align="center">
        <VCol cols="12">
          <h1 class="tt-text-headline-1">
            {{ normalizedTitle }}
          </h1>
        </VCol>
      </VRow>
      <VRow align="center">
        <VCol cols="6">
          <TTTextField
            :value="entity.name"
            label="Название"
            placeholder="Введите название"
            :disabled="isReadOnly('name') || loading"
            :loading="loading"
            @input="$emit('update:name', $event)"
          />
        </VCol>
      </VRow>

      <VRow align="center">
        <VCol cols="6">
          <TTAutocomplete
            :value="entity.competencyId"
            :items="competencies.items"
            :search-input.sync="searchCompetencies"
            :filter="customFilter"
            :disabled="isReadOnly('competencyId') || loading"
            :loading="competencies.loading"
            item-text="id"
            item-value="id"
            label="Компетенция"
            placeholder="Выберите компетенцию"
            @input="$emit('update:competencyId', $event)"
          >
            <template #selection="{ item }">
              <VListItemContent
                class="px-3 tt-text-body-2"
                v-text="item.name"
              />
            </template>
            <template #item="{ item }">
              <VListItemContent>
                <VListItemTitle v-text="item.name" />
                <VListItemSubtitle v-text="item.id" />
              </VListItemContent>
            </template>
          </TTAutocomplete>
        </VCol>
      </VRow>

      <VRow align="center">
        <VCol cols="6">
          <TTAutocomplete
            :value="entity.skillId"
            :search-input.sync="searchSkills"
            :items="skills.items"
            :filter="customFilter"
            :loading="skills.loading"
            :disabled="isReadOnly('skillId') || loading"
            item-text="id"
            item-value="id"
            label="Навык"
            placeholder="Выберите навык"
            @input="$emit('update:skillId', $event)"
          >
            <template #selection="{ item }">
              <VListItemContent
                class="px-3 tt-text-body-2"
                v-text="item.name"
              />
            </template>
            <template #item="{ item }">
              <VListItemContent>
                <VListItemTitle v-text="item.name" />
                <VListItemSubtitle v-text="item.id" />
              </VListItemContent>
            </template>
          </TTAutocomplete>
        </VCol>
      </VRow>

      <DateTime
        :value="entity.startAt"
        date-require
        date-lable="Дата начала"
        time-lable="Время начала"
        @input="$emit('update:startAt', $event)"
      />

      <DateTime
        :value="entity.finishedAt"
        date-lable="Дата окончания"
        time-lable="Время окончания"
        @input="$emit('update:finishedAt', $event)"
      />

      <VRow align="end">
        <VCol cols="3">
          <TTTextField
            :value="entity.cooldown"
            :loading="loading"
            :disabled="loading"
            label="Куллдаун (опционально)"
            placeholder="-"
            type="number"
            min="0"
            @input="$emit('update:cooldown', $event)"
          />
        </VCol>

        <VCol cols="3">
          <VCheckbox
            :value="entity.continuous"
            :disabled="loading"
            hide-details
            label="Цикличность (опционально)"
            @change="$emit('update:continuous', $event)"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="6">
          <TTTextField
            :value="entity.description"
            :loading="loading"
            label="Описание процедуры (опционально)"
            placeholder="Введите описание процедуры"
            @input="$emit('update:description', $event)"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <TTBtn
            color="tt-secondary"
            :disabled="loading"
            @click="handleCancel"
          >
            Отмена
          </TTBtn>

          <TTBtn
            class="ml-3"
            :disabled="loading"
            @click="handleSubmit"
          >
            {{ normalizedSubmit }}
          </TTBtn>
        </VCol>
      </VRow>
    </VContainer>
  </VForm>
</template>

<script>
import DateTime from './DateTime.vue';

export default {
  name: 'ProcedureForm',
  components: {
    DateTime,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    competencies: {
      type: Object,
      required: true,
    },
    skills: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      parentTeamCheckbox: false,
      searchSkills: null,
      searchCompetencies: null,
    };
  },

  computed: {
    normalizedTitle() {
      let title = 'Добавление процедуры';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование процедуры';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить процедуру';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  watch: {
    searchSkills() {
      this.$emit('update:searchSkills', this.searchSkills);
    },
    searchCompetencies() {
      this.$emit('update:searchCompetencies', this.searchCompetencies);
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$emit('submit', this.entity);
    },
    isReadOnly(key) {
      return this.readOnly.includes(key);
    },
  },
};
</script>
