var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VForm',[_c('VContainer',{attrs:{"fluid":""}},[_c('VRow',{attrs:{"align":"center"}},[_c('VCol',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"tt-text-headline-1"},[_vm._v(" "+_vm._s(_vm.normalizedTitle)+" ")])])],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',{attrs:{"cols":"6"}},[_c('TTTextField',{attrs:{"value":_vm.entity.name,"label":"Название","placeholder":"Введите название","disabled":_vm.isReadOnly('name') || _vm.loading,"loading":_vm.loading},on:{"input":function($event){return _vm.$emit('update:name', $event)}}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',{attrs:{"cols":"6"}},[_c('TTAutocomplete',{attrs:{"value":_vm.entity.competencyId,"items":_vm.competencies.items,"search-input":_vm.searchCompetencies,"filter":_vm.customFilter,"disabled":_vm.isReadOnly('competencyId') || _vm.loading,"loading":_vm.competencies.loading,"item-text":"id","item-value":"id","label":"Компетенция","placeholder":"Выберите компетенцию"},on:{"update:searchInput":function($event){_vm.searchCompetencies=$event},"update:search-input":function($event){_vm.searchCompetencies=$event},"input":function($event){return _vm.$emit('update:competencyId', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',{staticClass:"px-3 tt-text-body-2",domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}}])})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',{attrs:{"cols":"6"}},[_c('TTAutocomplete',{attrs:{"value":_vm.entity.skillId,"search-input":_vm.searchSkills,"items":_vm.skills.items,"filter":_vm.customFilter,"loading":_vm.skills.loading,"disabled":_vm.isReadOnly('skillId') || _vm.loading,"item-text":"id","item-value":"id","label":"Навык","placeholder":"Выберите навык"},on:{"update:searchInput":function($event){_vm.searchSkills=$event},"update:search-input":function($event){_vm.searchSkills=$event},"input":function($event){return _vm.$emit('update:skillId', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',{staticClass:"px-3 tt-text-body-2",domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}}])})],1)],1),_c('DateTime',{attrs:{"value":_vm.entity.startAt,"date-require":"","date-lable":"Дата начала","time-lable":"Время начала"},on:{"input":function($event){return _vm.$emit('update:startAt', $event)}}}),_c('DateTime',{attrs:{"value":_vm.entity.finishedAt,"date-lable":"Дата окончания","time-lable":"Время окончания"},on:{"input":function($event){return _vm.$emit('update:finishedAt', $event)}}}),_c('VRow',{attrs:{"align":"end"}},[_c('VCol',{attrs:{"cols":"3"}},[_c('TTTextField',{attrs:{"value":_vm.entity.cooldown,"loading":_vm.loading,"disabled":_vm.loading,"label":"Куллдаун (опционально)","placeholder":"-","type":"number","min":"0"},on:{"input":function($event){return _vm.$emit('update:cooldown', $event)}}})],1),_c('VCol',{attrs:{"cols":"3"}},[_c('VCheckbox',{attrs:{"value":_vm.entity.continuous,"disabled":_vm.loading,"hide-details":"","label":"Цикличность (опционально)"},on:{"change":function($event){return _vm.$emit('update:continuous', $event)}}})],1)],1),_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('TTTextField',{attrs:{"value":_vm.entity.description,"loading":_vm.loading,"label":"Описание процедуры (опционально)","placeholder":"Введите описание процедуры"},on:{"input":function($event){return _vm.$emit('update:description', $event)}}})],1)],1),_c('VRow',[_c('VCol',[_c('TTBtn',{attrs:{"color":"tt-secondary","disabled":_vm.loading},on:{"click":_vm.handleCancel}},[_vm._v(" Отмена ")]),_c('TTBtn',{staticClass:"ml-3",attrs:{"disabled":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.normalizedSubmit)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }